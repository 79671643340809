import { block as _block$ } from "million/react-server";
import { css } from "@emotion/css";
import Head from "next/head";
import Image from "next/image";
import useGradientPosition from "../hooks/useGradient";
const M$App = function App() {
  const {
    gradientPosition,
    isMobile,
    prefersReducedMotion
  } = useGradientPosition();
  const fixedGradientStyle = css`
    background: radial-gradient(circle at 50% 50%, #533b68, white);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;

    @media (prefers-contrast: more) {
      background: radial-gradient(circle at 50% 50%, #d9aed5, white);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  `;
  const gradientStyle = css`
    background: radial-gradient(
      circle at ${gradientPosition.x}px ${gradientPosition.y}px,
      #533b68,
      white
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;

    @media (prefers-contrast: more) {
      background: radial-gradient(
        circle at ${gradientPosition.x}px ${gradientPosition.y}px,
        #d9aed5,
        white
      );
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  `;
  const mobileGradientStyle = css`
    background: linear-gradient(90deg, #533b68 10%, white 90%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: gradient 3s infinite alternate;

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      100% {
        background-position: 100% 50%;
      }
    }

    @media (prefers-contrast: more) {
      background: linear-gradient(90deg, #d9aed5 10%, white 90%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      animation: gradient 3s infinite alternate;
    }
  `;
  return <>
      <Head>
        <title>Hi, I&apos;m Philip.</title>
        <meta name="description" content="Philip is a Software Engineer, specializing in beautiful Front-Ends and UI-Design." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.svg" />
      </Head>
      <main className="relative z-10">
        <div className="relative flex flex-col items-start pt-16 md:flex-row md:items-center">
          <div className="relative mb-1 rounded-full md:order-2 md:mb-0 md:ml-4">
            <div className="to-% rounded-full bg-gradient-to-br from-white to-[#533b68] p-[2px] contrast-more:to-[#D9AED5]">
              <Image className="rounded-full" src="/assets/img/new.webp" alt="Philip's profile picture" width={80} height={80} />
            </div>
          </div>

          <h1 className={`text-balance py-6 text-5xl font-bold leading-none tracking-tight md:text-6xl lg:text-7xl ${isMobile ? mobileGradientStyle : prefersReducedMotion ? fixedGradientStyle : gradientStyle}`} key={gradientPosition.x + gradientPosition.y} id="main-heading">
            Hi, I&apos;m Philip.
          </h1>
        </div>
        <h2 className="w-fit pt-5 text-4xl font-bold">
          I&apos;m a Software Engineer, specializing in{" "}
          <span className="bg-gradient-to-br from-white from-10% to-[#533b68] bg-clip-text text-transparent contrast-more:to-[#D9AED5]">
            Front-End-Development
          </span>{" "}
          and UI-Design.
        </h2>
        <h3 className="w-fit pt-5 text-3xl font-bold">
          Located in Munich. Currently at{" "}
          <a href="https://maibornwolff.de" className="underline underline-offset-8 transition-colors hover:text-[#9383A0] hover:contrast-more:text-[#DCB8DB]">
            MaibornWolff
          </a>
          .
        </h3>
        <p className="pt-10 text-xl">
          I&apos;m skilled in all things TypeScript, React, Next.js, Svelte, and
          Solid, as well as backend technologies like Nest.js and Node.js in
          general.
          <br />I am also skilled in the DevOps area, using Docker, Kubernetes,
          and Terraform, as well as CI/CD pipelines.
        </p>
        <p className="pt-10 text-xl">
          My focus areas are{" "}
          <a href="https://www.a11yproject.com" className="underline underline-offset-8 transition-colors hover:text-[#9383A0] hover:contrast-more:text-[#DCB8DB]">
            a11y
          </a>
          , performance, Artificial Intelligence and Modern Design Frameworks.
        </p>
        <p className="pt-10 text-xl">
          In my spare time, I maintain several Open Source Projects, including
          my own,{" "}
          <a href="https://github.com/frontendnetwork/veganify" className="underline underline-offset-8 transition-colors hover:text-[#9383A0] hover:contrast-more:text-[#DCB8DB]">
            Veganify.app
          </a>
          .
        </p>

        <p className="pt-10 text-xl">
          Sounds good? Contact me on{" "}
          <a href="https://linkedin.com/in/philip-brembeck" className="underline underline-offset-8 transition-colors hover:text-[#9383A0] hover:contrast-more:text-[#DCB8DB]">
            LinkedIn
          </a>{" "}
          or drop me an{" "}
          <a href="mailto:philip@brembeck.me" className="underline underline-offset-8 transition-colors hover:text-[#9383A0] hover:contrast-more:text-[#DCB8DB]">
            e-mail
          </a>
          .
        </p>
      </main>
      <svg width="180" height="180" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className="absolute bottom-0 left-[-5rem] right-0 top-[20rem] z-0 m-0 rotate-[-10deg] text-[#533b68] opacity-50 sm:top-[15rem] md:top-[9rem] lg:top-[10rem]" aria-label="Decorative stars for the background" aria-hidden="true" role="img" data-testid="svg">
        <g fill="#533b68">
          <path d="m509.52 460.92-12-130.92-12 130.92c-3.0156 33.98-17.145 66.02-40.203 91.16s-53.762 41.984-87.355 47.918c33.68 5.8398 64.492 22.641 87.645 47.789 23.156 25.148 37.355 57.242 40.395 91.289l12 130.92 12-130.92c3.1602-33.949 17.352-65.93 40.41-91.047s53.715-41.988 87.27-48.031c-33.645-5.9492-64.406-22.777-87.559-47.902-23.152-25.129-37.418-57.16-40.602-91.176z" />
          <path d="m842.04 444c-14.266-2.4805-27.316-9.5977-37.121-20.25-9.8086-10.656-15.824-24.246-17.117-38.672l-4.9219-55.441-5.0391 55.441c-1.2734 14.43-7.2812 28.035-17.094 38.691-9.8086 10.656-22.871 17.77-37.145 20.23 14.273 2.4609 27.336 9.5742 37.145 20.23 9.8125 10.656 15.82 24.262 17.094 38.691l5.0391 55.441 4.9219-55.441c1.293-14.426 7.3086-28.016 17.117-38.672 9.8047-10.652 22.855-17.77 37.121-20.25z" />
          <path d="m726.36 775.8-2.7617-30.121-2.6406 30.121h0.003907c-0.74609 7.832-4.0312 15.207-9.3633 20.992-5.332 5.7852-12.41 9.668-20.156 11.047 7.7617 1.3867 14.855 5.2852 20.188 11.094 5.3359 5.8125 8.6133 13.211 9.332 21.066l2.6406 30.121 2.7578-30.121c0.72656-7.8711 4.0195-15.285 9.3789-21.098 5.3555-5.8125 12.477-9.6992 20.262-11.062-7.7695-1.3555-14.875-5.2266-20.23-11.016-5.3555-5.7891-8.6602-13.176-9.4102-21.023z" />
        </g>
      </svg>
    </>;
};
const App = M$App;
export default App;