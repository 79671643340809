import { useRef, useCallback } from "react";

const useDebounce = (func: (...args: unknown[]) => void, wait: number) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunc = useCallback(
    (...args: unknown[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        func(...args);
      }, wait);
    },
    [func, wait],
  );

  return debouncedFunc;
};

export default useDebounce;
